import { QsHttpService } from '../core/http/qs-http.service';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';
import { IDataPayloadMultiple } from '../core/http/http.interface';
import { IBlog } from './blog.interface';

@Injectable({
  providedIn: 'root'
})
export class BlogService {
  protected readonly _http: QsHttpService = inject(QsHttpService);

  public getBlogs(page: number = 1, pageSize: number = 10): Observable<IDataPayloadMultiple<IBlog>> {
    const params = [
      'populate=coverImage',
      'sort=publishedAt:desc',
      `pagination[page]=${page}`,
      `pagination[pageSize]=${pageSize}`,
    ];

    return this._http.get<IDataPayloadMultiple<IBlog>>(`/blogs?${params.join('&')}`);
  }

  public getBlog(slug: string): Observable<IBlog> {
    const params = [
      `filters[slug][$eq]=${slug}`,
      'populate=seo.metaImage,coverImage',
    ];

    return this._http.get<IDataPayloadMultiple<IBlog>>(`/blogs/?${params.join('&')}`)
      .pipe(
        map((response) => {
          if (response.data && response.data.length > 0) {
            return response.data[0].attributes as unknown as IBlog;
          }

          return {} as unknown as IBlog;
        }),
      );
  }
}
